import React, { useState, useEffect } from 'react';
import { Stack } from 'react-bootstrap';
import firebase from 'config/firebase';
import Card from '../obras/card';
import { Timestamp } from 'firebase/firestore';

interface CardProps {
  id: string;
  titulo: string;
  data: number;
  foto: string;
  usuario: string;
  hora: string;
  local: string;
  idshows: string;
  estado: string;
  cidade: string;
  resumo: string;
  tipo: string;
  detalhes: string;
  endereço: string;
  criação: string;
  vizualizações: number;
  curtidas: number;
  datastring: string;
  idUsuario: string;
  email: string;
  nome: string;
  telefone: number;
  nomeusuario: string;
  artista: string[];
}

interface Shows {
  id: string;
  titulo: string;
  data: Timestamp;
  foto: string;
  usuario: string;
  hora: string;
  local: string;
  idshows: string;
  estado: string;
  cidade: string;
  resumo: string;
  tipo: string;
  detalhes: string;
  endereço: string;
  criação: string;
  vizualizações: number;
  datastring: string;
  artista: string[];
}

interface Usuário {
  id: string;
  email: string;
  nome: string;
  nomeusuario: string;
  telefone: number;
}

const Starter = () => {
  const [pesquisa] = useState('');
  const [cardsData, setCardsData] = useState<CardProps[]>([]);

  useEffect(() => {
    const fetchShowsAndUsers = async () => {
      try {
        const shows: Shows[] = [];
        const usuarios: Usuário[] = [];
        const showsResult = await firebase
          .firestore()
          .collection('Shows')
          .where('data', '>=', new Date())
          .get();

        console.log('Shows Result: ', showsResult.docs);

        showsResult.docs.forEach(doc => {
          const data = doc.data() as Omit<Shows, 'id'>;
          console.log('Data before adding: ', data);
          if (data.titulo.indexOf(pesquisa) >= 0) {
            shows.push({
              id: doc.id,
              ...data,
              data: data.data
            });
          }
        });

        console.log('Mapped Shows: ', shows);

        const usuariosResult = await firebase
          .firestore()
          .collection('Usuários')
          .get();

        usuariosResult.docs.forEach(doc => {
          const data = doc.data();
          const infoPessoais = data.InfoPessoais || {};
          usuarios.push({
            id: doc.id,
            nome: infoPessoais.Nome || '',
            nomeusuario: infoPessoais.NomeUsuario || '',
            email: infoPessoais.Email || '',
            telefone: infoPessoais.Telefone || ''
          });
        });

        const combinedData: CardProps[] = shows.map(show => {
          const usuario = usuarios.find(u => u.id === show.usuario);
          return {
            ...show,
            data: show.data.seconds * 1000,
            nome: usuario?.nome || '',
            nomeusuario: usuario?.nomeusuario || '',
            email: usuario?.email || '',
            telefone: usuario?.telefone || 0,
            curtidas: 0,
            idUsuario: usuario?.id || '',
            artista: show.artista || []
          };
        });

        console.log('Combined Data for Cards: ', combinedData);
        setCardsData(combinedData);
      } catch (error) {
        console.error('Erro ao carregar os dados:', error);
      }
    };

    fetchShowsAndUsers();
  }, [pesquisa]);

  return (
    <Stack>
      <div className="row">
        <div className="col-12">
          <div className="row container-fluid content-header float-end">
            {cardsData.map(item => (
              <Card
                key={item.id}
                id={item.id}
                img={item.foto}
                usuario={item.usuario}
                data={item.data}
                local={item.local}
                titulo={item.titulo}
                idshows={item.idshows}
                estado={item.estado}
                cidade={item.cidade}
                resumo={item.resumo}
                tipo={item.tipo}                
                vizualizações={item.vizualizações}
                curtidas={item.curtidas}                
                nome={item.nome}
                nomeusuario={item.nomeusuario}                
              />
            ))}
          </div>
        </div>
      </div>
    </Stack>
  );
};

export default Starter;
